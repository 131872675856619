import type { FC } from 'react';
import React from 'react';
import { Col, Form, InputGroup, Row } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import { CLASSNAME } from './publishersListPage';

interface Props {
    searchValue?: string | null;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PageControls: FC<Props> = ({ searchValue, onSearchChange }) => (
    <div className="PageControls mb-3">
        <Row>
            <Col xs={12} md={6} lg={4}>
                <InputGroup>
                    <InputGroup.Prepend>
                        <InputGroup.Text>
                            <GlyphIcon name="search" size={16} />
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                        type="text"
                        placeholder={formatMessage(
                            `${CLASSNAME}.searchPlaceholder`
                        )}
                        onChange={onSearchChange}
                        value={searchValue || ''}
                    />
                </InputGroup>
            </Col>
        </Row>
    </div>
);

export default PageControls;
