import React, { useCallback, useState } from 'react';
import {
    BsDropdown,
    DropdownButton,
    Modal,
} from '@theorchard/suite-components';
import {
    formatMessage,
    Segment,
    useIdentity,
} from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import { FEATURE_FLAGS } from 'src/constants';
import type { SongWriter } from 'src/types';

interface Props {
    category: string;
    songWriter: SongWriter;
    onSongWriterView: (songWriter: SongWriter) => void;
    onSongWriterEdit: (songWriter: SongWriter) => void;
    onSongWriterDelete: (songWriter: SongWriter) => void;
}

const SongWriterDropdown: React.FC<Props> = ({
    category,
    songWriter,
    onSongWriterView,
    onSongWriterEdit,
    onSongWriterDelete,
}) => {
    const identity = useIdentity();
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const onToggle = useCallback(
        (isOpen: boolean) => {
            if (isOpen) void Segment.trackEvent('Click - ...', { category });
        },
        [category]
    );

    const onView = useCallback(
        () => onSongWriterView(songWriter),
        [songWriter, onSongWriterView]
    );
    const onEdit = useCallback(
        () => onSongWriterEdit(songWriter),
        [songWriter, onSongWriterEdit]
    );
    const onDelete = useCallback(() => {
        onSongWriterDelete(songWriter);
        setDeleteModalOpen(false);
    }, [songWriter, onSongWriterDelete]);

    return (
        <DropdownButton
            className="DropdownIconButton"
            onToggle={onToggle}
            title={<GlyphIcon name="optionsHorizontal" size={16} />}
            alignRight
            variant="link"
        >
            <BsDropdown.Item onClick={onView}>
                {formatMessage('generic.view')}
            </BsDropdown.Item>

            <BsDropdown.Item onClick={onEdit}>
                {formatMessage('generic.edit')}
            </BsDropdown.Item>

            {identity.features[FEATURE_FLAGS.ALLOW_DELETE_SONG_WRITER] && (
                <>
                    <BsDropdown.Item
                        disabled={!!songWriter.compositions.length}
                        onClick={() => setDeleteModalOpen(true)}
                    >
                        {formatMessage('generic.delete')}
                    </BsDropdown.Item>
                    <Modal
                        isOpen={deleteModalOpen}
                        onRequestClose={() => setDeleteModalOpen(false)}
                        header={formatMessage('songWriters.deleteModalHeader')}
                        cancelTitle={formatMessage(
                            'songWriters.deleteCancellation'
                        )}
                        confirmTitle={formatMessage(
                            'songWriters.deleteConfirmation'
                        )}
                        onConfirm={onDelete}
                    >
                        {formatMessage('songWriters.deleteWarning')}
                    </Modal>
                </>
            )}
        </DropdownButton>
    );
};

export default SongWriterDropdown;
