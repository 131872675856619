import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import PublisherDropdown from 'src/pages/publishersListPage/publisherDropdown';
import { CLASSNAME } from 'src/pages/publishersListPage/publishersListPage';
import type { Publisher } from 'src/types';

interface Props {
    publisher: Publisher;
    onPublisherView: (publisher: Publisher) => void;
    onPublisherEdit: (publisher: Publisher) => void;
    onPublisherDelete: (publisher: Publisher) => void;
}

const renderNotRegisteredCell = () => (
    <div className={`${CLASSNAME}-not-registered`}>
        <span>{formatMessage('generic.notRegistered')}</span>
        <GlyphIcon name="warning" size={16} />
    </div>
);

const PublisherRow: FC<Props> = ({
    publisher,
    onPublisherView,
    onPublisherEdit,
    onPublisherDelete,
}) => {
    const associatedSongWriters = useMemo(
        () => publisher.agreements.filter(a => a.songWriter),
        [publisher.agreements]
    );

    const onView = useCallback(
        () => onPublisherView(publisher),
        [publisher, onPublisherView]
    );

    return (
        <tr>
            <td>
                <div onClick={onView}>{publisher.name}</div>
            </td>
            <td>{publisher.pro || renderNotRegisteredCell()}</td>
            <td>{publisher.ipi || renderNotRegisteredCell()}</td>
            <td>{associatedSongWriters.length}</td>
            <td>
                <PublisherDropdown
                    publisher={publisher}
                    onPublisherView={onPublisherView}
                    onPublisherDelete={onPublisherDelete}
                    onPublisherEdit={onPublisherEdit}
                />
            </td>
        </tr>
    );
};

export default PublisherRow;
