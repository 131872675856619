/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

import * as Types from 'src/data/schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type PublishingUpdateSongWriterMetaDataMutationVariables = Types.Exact<{
    id: Types.Scalars['ID']['input'];
    name: Types.Scalars['String']['input'];
    ipi?: Types.InputMaybe<Types.Scalars['String']['input']>;
    pro?: Types.InputMaybe<Types.Scalars['String']['input']>;
    controlled?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
    label: Types.LabelUuid;
    professionallyKnownAs?: Types.InputMaybe<
        | Array<Types.Scalars['String']['input']>
        | Types.Scalars['String']['input']
    >;
}>;

export type PublishingUpdateSongWriterMetaDataMutation = {
    __typename?: 'Mutation';
    updatePublishingSongWriterMetaData: {
        __typename?: 'PublishingSongWriter';
        id: string;
        ipi?: string | null;
        legalName: string;
        pro?: string | null;
        professionallyKnownAs: Array<string>;
    };
};

export const PublishingUpdateSongWriterMetaDataDocument = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'PublishingUpdateSongWriterMetaData' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ID' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'name' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'String' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'ipi' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pro' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'controlled' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Boolean' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'label' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'LabelUuid' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'professionallyKnownAs' },
                    },
                    type: {
                        kind: 'ListType',
                        type: {
                            kind: 'NonNullType',
                            type: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'String' },
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: {
                            kind: 'Name',
                            value: 'updatePublishingSongWriterMetaData',
                        },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                        {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'name',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'ipi',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ipi',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'pro',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'pro',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'controlled',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'controlled',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'label',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'label',
                                                },
                                            },
                                        },
                                        {
                                            kind: 'ObjectField',
                                            name: {
                                                kind: 'Name',
                                                value: 'professionallyKnownAs',
                                            },
                                            value: {
                                                kind: 'Variable',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'professionallyKnownAs',
                                                },
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'ipi' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'legalName' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pro' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'professionallyKnownAs',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    PublishingUpdateSongWriterMetaDataMutation,
    PublishingUpdateSongWriterMetaDataMutationVariables
>;
