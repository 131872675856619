import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import {
    BsDropdown,
    Button,
    DropdownButton,
    Modal,
} from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import type { Publisher } from 'src/types';

interface Props {
    publisher: Publisher;
    onPublisherView: (publisher: Publisher) => void;
    onPublisherEdit: (publisher: Publisher) => void;
    onPublisherDelete: (publisher: Publisher) => void;
}

const PublisherDropdown: FC<Props> = ({
    publisher,
    onPublisherView,
    onPublisherEdit,
    onPublisherDelete,
}) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const songWriters = useMemo(
        () => publisher.agreements.filter(a => a.songWriter),
        [publisher.agreements]
    );

    const onView = useCallback(
        () => onPublisherView(publisher),
        [publisher, onPublisherView]
    );
    const onEdit = useCallback(
        () => onPublisherEdit(publisher),
        [publisher, onPublisherEdit]
    );
    const onDelete = useCallback(() => {
        onPublisherDelete(publisher);
        setDeleteModalOpen(false);
    }, [publisher, onPublisherDelete]);

    return (
        <DropdownButton
            className="DropdownIconButton"
            title={<GlyphIcon name="optionsHorizontal" size={16} />}
            alignRight
            variant="link"
        >
            <BsDropdown.Item onClick={onView}>
                {formatMessage('generic.view')}
            </BsDropdown.Item>
            <BsDropdown.Item onClick={onEdit}>
                {formatMessage('generic.edit')}
            </BsDropdown.Item>

            <>
                <BsDropdown.Item
                    disabled={!!songWriters.length}
                    onClick={() => setDeleteModalOpen(true)}
                >
                    {formatMessage('generic.delete')}
                </BsDropdown.Item>
                <Modal.Custom
                    isOpen={deleteModalOpen}
                    onRequestClose={() => setDeleteModalOpen(false)}
                    header={formatMessage('publishers.deleteModalHeader')}
                    customFooter={
                        <footer className="ModalFooter ReactModal-footer">
                            <Button
                                variant="secondary"
                                onClick={() => setDeleteModalOpen(false)}
                            >
                                {formatMessage('publishers.deleteCancellation')}
                            </Button>
                            <Button variant="primary" onClick={onDelete}>
                                {formatMessage('publishers.deleteConfirmation')}
                            </Button>
                        </footer>
                    }
                >
                    {formatMessage('publishers.deleteWarning')}
                </Modal.Custom>
            </>
        </DropdownButton>
    );
};

export default PublisherDropdown;
