import React, { useCallback } from 'react';
import { Field, SearchDropdown } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { usePublishingSongWriters } from 'src/data/queries';
import type { PublishingSongWritersFilterLabelInput } from 'src/data/queries';
import type {
    DropdownOption,
    Label,
    SearchableDropdownOption,
    SongWriter,
} from 'src/types';

export const defaultSongWriterValues: SongWriter = {
    id: '',
    legalName: '',
    professionallyKnownAs: [],
    vendor: {
        id: { vendorId: 0, subaccountId: 0 },
        uuid: '',
        name: '',
    },
    ipi: null,
    pro: null,
    compositions: [],
    agreements: [],
};

interface Props {
    label: Label | null;
    onChange: (value: SongWriter | undefined) => void;
}

const generateOptions = (swList: SongWriter[] = []) => [
    {
        label: '+ Add New Songwriter',
        value: '',
        data: defaultSongWriterValues,
    },
    ...swList.map(sw => ({ label: sw.legalName, value: sw.id, data: sw })),
];

const formatOptionLabel = (data: DropdownOption<SongWriter>) => (
    <>{data.label}</>
);

const SongWriterAsyncSelect: React.FC<Props> = ({ label, onChange }) => {
    const filter = {
        label,
    } as PublishingSongWritersFilterLabelInput;

    const { data, refetch } = usePublishingSongWriters(filter, 1);

    const loadPublishingSongWritersOptions = async (
        legalNameSearch: string
    ): Promise<DropdownOption<SongWriter>[]> => {
        const opts = legalNameSearch
            ? { legalNameSearch, label }
            : { label: null };

        const { data: refetchData } = await refetch(
            opts as PublishingSongWritersFilterLabelInput,
            1
        );

        return generateOptions(
            refetchData?.publishingSongWriters?.songWriters as SongWriter[]
        );
    };

    const onSongWriterSelect = useCallback(
        (value: SearchableDropdownOption<SongWriter>) => {
            onChange(value?.data);
        },
        [onChange]
    );

    if (!data) return null;

    return (
        <Field
            controlId="songWriterAsyncSelect"
            labelText={formatMessage('generic.songWriter')}
            isRequired
        >
            <SearchDropdown
                isClearable={false}
                onChange={onSongWriterSelect}
                defaultOptions={generateOptions(data.songWriters)}
                onLoadOptions={loadPublishingSongWritersOptions}
                formatOptionLabel={formatOptionLabel}
                placeholder={formatMessage('generic.searchForSongWriter')}
            />
        </Field>
    );
};

export default SongWriterAsyncSelect;
