import type { FC } from 'react';
import React, { useEffect, useCallback, useState } from 'react';
import {
    Button,
    Card,
    LoadingIndicator,
    HelpTooltip,
} from '@theorchard/suite-components';
import {
    formatMessage,
    Segment,
    useIdentity,
} from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import { useHistory } from 'react-router-dom';
import EditSongWriter from 'src/components/editSongWriter';
import EmptyState from 'src/components/emptyState';
import ReadError from 'src/components/errors/read-error';
import HeaderLabelPicker from 'src/components/headerLabelPicker';
import { SHOW_ALL_LABEL_UUID } from 'src/components/headerLabelPicker/labelSearchDropdown';
import RegisterNewSongwriter from 'src/components/registerNewSongwriter';
import ViewSongWriter from 'src/components/viewSongWriter';
import { FEATURE_FLAGS } from 'src/constants';
import { useDeleteSongWriter } from 'src/data/mutations/deleteSongWriter/deleteSongWriter';
import { usePublishingSongWriters } from 'src/data/queries';
import { CompanyBrandName } from 'src/data/schemaTypes';
import PageControls from 'src/pages/songWriterListPage/pageControls';
import SongWriterTable from 'src/pages/songWriterListPage/songWriterTable';
import { useApplicationContext } from 'src/utils/applicationContext';
import { useIsEmployeeWithFullAccess } from 'src/utils/helpers';
import { useLocationSearchQuery, useLocationWithParams } from 'src/utils/route';
import { songWritersUrl } from 'src/utils/urls';
import { isValidIpi } from 'src/utils/validators';
import type { BrandOption } from 'src/components/brandSelector';
import type { PublishingSongWritersFilter } from 'src/data/schemaTypes';
import type { Label, SongWriter } from 'src/types';

export const CLASSNAME = 'SongWriterListPage';
export const SONGWRITERS_PER_PAGE = 25;

interface PublishingSongWritersFilterLabel
    extends Omit<PublishingSongWritersFilter, 'label'> {
    label: Label | null;
    legalNameSearch: string | null;
    legalNameAndIpiSearch: boolean | null;
}

const enum URLParams {
    BRAND = 'brand',
    WRITER = 'songwriter',
    IPI = 'ipi',
}

const extractValuesFromSearchQuery = (
    searchQuery: URLSearchParams,
    isEmployeeWithFullAccess: boolean = false
) => {
    const brandValue =
        isEmployeeWithFullAccess && searchQuery.get(URLParams.BRAND)
            ? decodeURIComponent(searchQuery.get(URLParams.BRAND)!)
            : undefined;

    const songwriter = decodeURIComponent(
        searchQuery.get(URLParams.WRITER) || ''
    );

    return {
        songwriter,
        brand: Object.values(CompanyBrandName).includes(
            brandValue as CompanyBrandName
        )
            ? (brandValue as CompanyBrandName)
            : undefined,
    };
};

const SongWriterListPage: FC<object> = () => {
    const isPKAAccessible =
        useIdentity().features[FEATURE_FLAGS.ACCESS_PKA_PROPERTY];
    const history = useHistory();
    const {
        pageFilters,
        updatePageFilters,
        label: commonLabel,
    } = useApplicationContext();

    const { params } = useLocationWithParams();
    const searchQuery = useLocationSearchQuery();
    const isEmployeeWithFullAccess = useIsEmployeeWithFullAccess();

    const category = 'SongWriters';
    const page = parseInt(params[0] || '1', 10);
    const {
        brand: brandFromSearchQuery,
        songwriter: songwriterFromSearchQuery,
    } = extractValuesFromSearchQuery(searchQuery, isEmployeeWithFullAccess);

    const brand =
        brandFromSearchQuery || (pageFilters?.brand as CompanyBrandName);
    const songwriterSearch =
        songwriterFromSearchQuery ||
        pageFilters?.songWritersPageSongwriterSearch ||
        null;

    const deleteSongWriter = useDeleteSongWriter();
    const [filter, setFilter] = useState<PublishingSongWritersFilterLabel>({
        label: null,
        brand: brand,
        legalNameSearch: songwriterSearch,
        legalNameAndIpiSearch:
            songwriterSearch && !Number.isNaN(parseInt(songwriterSearch, 10))
                ? true
                : null,
    });
    const [selectedSongWriter, setSelectedSongWriter] =
        useState<SongWriter | null>(null);
    const [detailMode, setDetailMode] = useState<string>('');

    const [pageSize, setPageSize] = useState(SONGWRITERS_PER_PAGE);
    const handleChangedPageSize = (newPageSize: number) => {
        setPageSize(newPageSize);
    };

    useEffect(() => {
        if (
            filter.label?.uuid === commonLabel?.uuid ||
            (commonLabel?.uuid === SHOW_ALL_LABEL_UUID && !filter.label)
        )
            return;

        setFilter(f => ({
            ...f,
            label:
                commonLabel && commonLabel.uuid !== SHOW_ALL_LABEL_UUID
                    ? commonLabel
                    : null,
        }));
    }, [commonLabel, filter]);

    const {
        data: songWritersQueryData,
        loading,
        error,
    } = usePublishingSongWriters(filter, page, pageSize);

    const totalPages = Math.ceil(
        (songWritersQueryData?.totalCount || 0) / pageSize
    );
    useEffect(() => {
        history.push(songWritersUrl(page, filter, isEmployeeWithFullAccess));
    }, [history, page, filter, isEmployeeWithFullAccess]);

    const onChangeSearch = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value;
            updatePageFilters({
                songWritersPageSongwriterSearch: isValidIpi(inputValue)
                    ? null
                    : inputValue,
            });

            setFilter(f => {
                const { legalNameSearch, ...newFilter } = f;

                // This is to allow users to filter by partial IPIs & writers with numbers in their name
                const isNumeric = !Number.isNaN(parseInt(inputValue, 10));
                return {
                    ...newFilter,
                    legalNameSearch: inputValue,
                    ...(isPKAAccessible && {
                        professionallyKnownAs: inputValue,
                    }),
                    ...(isNumeric && { legalNameAndIpiSearch: true }),
                };
            });
        },
        [updatePageFilters, isPKAAccessible]
    );

    const onBrandChange = useCallback(
        (option?: BrandOption) => {
            updatePageFilters({ brand: option?.value || null });
            setFilter(f => ({
                ...f,
                brand: option?.value as CompanyBrandName,
            }));
        },
        [updatePageFilters]
    );

    const onPaginationChange = useCallback(
        (newPage: number) => {
            history.push(
                songWritersUrl(newPage + 1, filter, isEmployeeWithFullAccess)
            );
        },
        [history, filter, isEmployeeWithFullAccess]
    );

    // Check if the page number is valid and redirect if necessary to the first page when the sonwriters list changes
    // If the page number is greater than the total number of pages, redirect to the first page
    useEffect(() => {
        if (!loading && songWritersQueryData?.totalCount !== undefined) {
            const newPageNumber =
                totalPages === 0 || page > totalPages ? 1 : page;
            if (newPageNumber !== page) {
                history.push(
                    songWritersUrl(
                        newPageNumber,
                        filter,
                        isEmployeeWithFullAccess
                    )
                );
            }
        }
    }, [
        loading,
        songWritersQueryData,
        page,
        history,
        totalPages,
        filter,
        isEmployeeWithFullAccess,
    ]);

    const onViewSongWriter = useCallback((songWriter: SongWriter) => {
        void Segment.trackEvent('Click - View Writer', {
            category,
            songWriterId: songWriter.id,
            songWriterLegalName: songWriter.legalName,
            songWriterProfessionallyKnownAs: songWriter.professionallyKnownAs,
            songWriterIpi: songWriter.ipi,
            songWriterPro: songWriter.pro,
        });
        setSelectedSongWriter(songWriter);
        setDetailMode('view');
    }, []);

    const onEditSongWriter = useCallback((songWriter: SongWriter) => {
        void Segment.trackEvent('Click - Edit Writer', {
            category,
            songWriterId: songWriter.id,
            songWriterLegalName: songWriter.legalName,
            songWriterProfessionallyKnownAs: songWriter.professionallyKnownAs,
            songWriterIpi: songWriter.ipi,
            songWriterPro: songWriter.pro,
        });

        setSelectedSongWriter(songWriter);
        setDetailMode('edit');
    }, []);

    const onDeleteSongWriter = useCallback(
        ({ id, legalName }: SongWriter) => {
            void Segment.trackEvent('Click - Delete Song Writer', {
                category: 'Delete Songwriter',
                songWriterId: id,
                songWriterName: legalName,
            });
            void deleteSongWriter({ variables: { id } });
        },
        [deleteSongWriter]
    );

    const toNewSongWriter = useCallback(() => {
        void Segment.trackEvent('Click - New Writer', { category });

        setSelectedSongWriter(null);
        setDetailMode('new');
    }, []);

    const onModeReset = useCallback(() => {
        setSelectedSongWriter(null);
        setDetailMode('');
    }, []);

    if (!songWritersQueryData) return <LoadingIndicator />;

    if (error) return <ReadError error={error} />;

    const isNewSongWriterDisabled = !filter.label;
    const newSongWriterButton = (
        <Button
            disabled={isNewSongWriterDisabled}
            className="icon-left"
            variant="primary"
            onClick={toNewSongWriter}
            size="lg"
        >
            <GlyphIcon name="plus" size={12} />
            {formatMessage('songWriters.newWriter')}
        </Button>
    );
    return (
        <div className={CLASSNAME}>
            {detailMode === 'edit' && (
                <EditSongWriter
                    isOpen
                    onClose={onModeReset}
                    songWriter={selectedSongWriter}
                />
            )}
            {detailMode === 'new' && (
                <RegisterNewSongwriter
                    isOpen
                    onClose={onModeReset}
                    headerLabel={formatMessage(
                        'songWriterManager.registerNewSongWriter'
                    )}
                    validated
                />
            )}
            <ViewSongWriter
                isOpen={Boolean(selectedSongWriter) && detailMode === 'view'}
                songWriter={selectedSongWriter}
                onClose={onModeReset}
            />

            <div className="AppHeader">
                <HeaderLabelPicker />
                {isNewSongWriterDisabled ? (
                    <HelpTooltip
                        message={formatMessage(
                            'songWriterManager.newSongWriterDisabled'
                        )}
                        tooltipPlacement="left"
                        id="new-song-writer-tooltip"
                    >
                        {newSongWriterButton}
                    </HelpTooltip>
                ) : (
                    newSongWriterButton
                )}
            </div>

            <PageControls
                searchValue={songwriterSearch}
                onSearchChange={onChangeSearch}
                isEmployeeWithFullAccess={isEmployeeWithFullAccess}
                initialBrandValue={brand}
                onBrandChange={onBrandChange}
            />

            <div className="PageContent">
                <Card body>
                    {!songWritersQueryData?.songWriters?.length ? (
                        <EmptyState isLoading={loading} page="songWriters" />
                    ) : (
                        <SongWriterTable
                            page={page - 1}
                            totalPages={totalPages}
                            totalCount={songWritersQueryData.totalCount || 0}
                            pageSize={pageSize}
                            category={category}
                            songWriters={songWritersQueryData?.songWriters}
                            onPaginationChange={onPaginationChange}
                            onSongWriterView={onViewSongWriter}
                            onSongWriterEdit={onEditSongWriter}
                            onSongWriterDelete={onDeleteSongWriter}
                            onSetPageSize={handleChangedPageSize}
                        />
                    )}
                </Card>
            </div>
        </div>
    );
};

export default SongWriterListPage;
