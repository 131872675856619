import React from 'react';
import { Select } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { BrandIcon } from '@theorchard/suite-icons';
import { CompanyBrandName } from 'src/data/schemaTypes';

const CLASSNAME = 'BrandSelector';

export interface BrandOption {
    label: string;
    value: string;
    icon: JSX.Element;
}

const BRAND_OPTIONS: BrandOption[] = [
    {
        label: formatMessage('generic.orchard'),
        value: CompanyBrandName.THEORCHARD,
        icon: <BrandIcon brand="orchard" />,
    },
    {
        label: formatMessage('generic.awal'),
        value: CompanyBrandName.AWAL,
        icon: <BrandIcon brand="awal" />,
    },
];
interface Props {
    initialValue?: CompanyBrandName;
    onChange: (value?: BrandOption) => void;
}

const BrandSelector: React.FC<Props> = ({ initialValue, onChange }) => {
    return (
        <Select
            className={CLASSNAME}
            placeholder={formatMessage('generic.brand')}
            onChange={onChange}
            initialValue={initialValue}
            options={BRAND_OPTIONS}
            hideFilter
            menuWidth="100%"
            menuMinWidth="100%"
        />
    );
};

export default BrandSelector;
