import React, { useState } from 'react';
import {
    BsDropdown,
    DropdownButton,
    Modal,
} from '@theorchard/suite-components';
import { formatMessage, useIdentity } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import { Decimal } from 'decimal.js';
import { Link } from 'react-router-dom';
import { FEATURE_FLAGS } from 'src/constants';
import { CLASSNAME } from 'src/pages/songListPage/songListPage';
import SongWritersList from 'src/pages/songListPage/songWritersList';
import { utcToDateString } from 'src/utils/date';
import { getSum } from 'src/utils/helpers';
import { songUrl } from 'src/utils/urls';
import type { CompositionExtended } from 'src/types';

interface Props {
    song: CompositionExtended;
    isDraft: boolean;
    showPubSongId: boolean;
    onClickSong: () => void;
    onEdit: (id: string) => void;
    onDelete: (id: string) => void;
    onToggleDropdown: (isOpen: boolean) => void;
}

const renderOwnership = (ownerships: string[]) => {
    const ownership = getSum(
        ownerships.map(o => new Decimal(o || 0))
    ).toString();
    if (ownership === '0')
        return (
            <div className={`${CLASSNAME}-none`}>
                {formatMessage('songs.noOwnership')}
            </div>
        );
    return (
        <div
            className={`${CLASSNAME}-ownership`}
        >{`${ownership}% ${formatMessage('generic.ownership')}`}</div>
    );
};

const SongRow: React.FC<Props> = ({
    song,
    isDraft,
    showPubSongId,
    onClickSong,
    onEdit,
    onDelete,
    onToggleDropdown,
}) => {
    const identity = useIdentity();
    const isSongDeletionAllowed =
        identity.features[FEATURE_FLAGS.ALLOW_DELETE_SONG];
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    return (
        <tr key={song.id}>
            <td>
                <Link to={songUrl(song.id)} onClick={onClickSong}>
                    {song.title}
                </Link>
            </td>
            {showPubSongId && <td>{song.pubSongId}</td>}
            <td>
                <SongWritersList agreements={song.agreements} />
            </td>
            <td>
                {song.associatedGlobalSoundRecordings.length +
                    song.associatedLabelSoundRecordings.length ===
                0 ? (
                    <div className={`${CLASSNAME}-none`}>
                        {formatMessage('generic.none')}
                    </div>
                ) : (
                    song.associatedGlobalSoundRecordings.length +
                    song.associatedLabelSoundRecordings.length
                )}
            </td>
            <td>
                {renderOwnership(
                    song.agreements.map(agreement => agreement.split)
                )}
            </td>
            <td>
                {utcToDateString(
                    isDraft
                        ? song.modifiedAt || song.createdAt
                        : song.submittedAt ?? ''
                )}
            </td>
            <td>
                <DropdownButton
                    className="DropdownIconButton"
                    onToggle={onToggleDropdown}
                    title={<GlyphIcon name="optionsHorizontal" size={16} />}
                    variant="link"
                    alignRight
                >
                    <BsDropdown.Item onClick={() => onEdit(song.id)}>
                        {formatMessage('generic.edit')}
                    </BsDropdown.Item>

                    {isSongDeletionAllowed && (
                        <>
                            <BsDropdown.Item
                                onClick={() => setDeleteModalOpen(true)}
                            >
                                {formatMessage('generic.delete')}
                            </BsDropdown.Item>
                            <Modal
                                isOpen={deleteModalOpen}
                                onRequestClose={() => setDeleteModalOpen(false)}
                                header={formatMessage(
                                    'songs.deleteModalHeader'
                                )}
                                cancelTitle={formatMessage(
                                    'songs.deleteCancellation'
                                )}
                                confirmTitle={formatMessage(
                                    'songs.deleteConfirmation'
                                )}
                                onConfirm={() => {
                                    onDelete(song.id);
                                    setDeleteModalOpen(false);
                                }}
                            >
                                {formatMessage('songs.deleteWarning')}
                            </Modal>
                        </>
                    )}
                </DropdownButton>
            </td>
        </tr>
    );
};

export default SongRow;
