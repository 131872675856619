import React from 'react';
import { Col, InputGroup, Row, Form } from '@theorchard/suite-components';
import { formatMessage, useIdentity } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import BrandSelector from 'src/components/brandSelector';
import { FEATURE_FLAGS } from 'src/constants';
import { CLASSNAME } from './songWriters';
import type { BrandOption } from 'src/components/brandSelector';
import type { CompanyBrandName } from 'src/data/schemaTypes';

interface Props {
    searchValue?: string | null;
    onSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isEmployeeWithFullAccess: boolean;
    initialBrandValue?: CompanyBrandName;
    onBrandChange: (value?: BrandOption) => void;
}

const PageControls: React.FC<Props> = ({
    searchValue,
    onSearchChange,
    isEmployeeWithFullAccess,
    initialBrandValue,
    onBrandChange,
}) => {
    const isPKAAccessible =
        useIdentity().features[FEATURE_FLAGS.ACCESS_PKA_PROPERTY];

    return (
        <div className="PageControls mb-3">
            <Row>
                <Col xs={12} md={6} lg={4}>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                <GlyphIcon name="search" size={16} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="text"
                            placeholder={formatMessage(
                                isPKAAccessible
                                    ? `${CLASSNAME}.searchPlaceholderWithPKA`
                                    : `${CLASSNAME}.searchPlaceholder`
                            )}
                            value={searchValue || ''}
                            onChange={onSearchChange}
                        />
                    </InputGroup>
                </Col>
                {isEmployeeWithFullAccess && (
                    <Col xs={6} md={3} lg={2}>
                        <BrandSelector
                            initialValue={initialBrandValue}
                            onChange={onBrandChange}
                        />
                    </Col>
                )}
            </Row>
        </div>
    );
};

export default PageControls;
