import React, { Fragment, useCallback } from 'react';
import { Pill } from '@theorchard/suite-components';
import { formatMessage, useIdentity } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import { groupBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { FEATURE_FLAGS } from 'src/constants';
import SongWriterDropdown from 'src/pages/songWriterListPage/songWriterDropdown';
import { CLASSNAME } from 'src/pages/songWriterListPage/songWriters';
import { songUrl } from 'src/utils/urls';
import type { SongWriter } from 'src/types';

interface Props {
    category: string;
    songWriter: SongWriter;
    onSongWriterView: (songWriter: SongWriter) => void;
    onSongWriterEdit: (songWriter: SongWriter) => void;
    onSongWriterDelete: (songWriter: SongWriter) => void;
}

type History = ReturnType<typeof useHistory>;
type AgreementCompositions = SongWriter['agreements'][0]['compositions'];

const renderNotRegisteredCell = () => (
    <div className={`${CLASSNAME}-not-registered`}>
        <span>{formatMessage('generic.notRegistered')}</span>
        <GlyphIcon name="warning" size={16} />
    </div>
);

const CompositionList: React.FC<{
    compositions?: AgreementCompositions;
    history: History;
}> = ({ compositions, history }) => (
    <div className={`${CLASSNAME}-link-list`}>
        {compositions?.map((composition, index) => (
            <Fragment key={composition.id}>
                <span
                    className={`${CLASSNAME}-link`}
                    onClick={() => history.push(songUrl(composition.id))}
                >
                    {composition.title}
                </span>
                {index !== compositions.length - 1 && <hr />}
            </Fragment>
        ))}
    </div>
);

const renderAssociatedCompositionsCell = (
    { id, agreements }: SongWriter,
    history: History
) => {
    const grouped = groupBy(agreements, 'controlled');

    const compositions: Map<string, AgreementCompositions> = Object.keys(
        grouped
    ).reduce((map, key) => {
        map.set(
            key,
            grouped[key].flatMap(i => i.compositions)
        );
        return map;
    }, new Map());

    if (!compositions.get('true')?.length && !compositions.get('false')?.length)
        return <div className={`${CLASSNAME}-pill-zero`}>0</div>;

    return (
        <>
            {compositions.get('true') && (
                <Pill
                    className={`${CLASSNAME}-pill`}
                    text={`${compositions.get('true')?.length} ${formatMessage('generic.controlled')}`}
                    popoverOptions={{
                        id: `controlled-${id}`,
                        content: (
                            <CompositionList
                                compositions={compositions.get('true')}
                                history={history}
                            />
                        ),
                    }}
                />
            )}

            {compositions.get('false') && (
                <Pill
                    className={`${CLASSNAME}-pill`}
                    text={`${compositions.get('false')?.length} ${formatMessage('generic.nonControlled')}`}
                    popoverOptions={{
                        id: `non-controlled-${id}`,
                        content: (
                            <CompositionList
                                compositions={compositions.get('false')}
                                history={history}
                            />
                        ),
                    }}
                />
            )}
        </>
    );
};

const SongWriterRow: React.FC<Props> = ({
    category,
    songWriter,
    onSongWriterView,
    onSongWriterEdit,
    onSongWriterDelete,
}) => {
    const isPKAAccessible =
        useIdentity().features[FEATURE_FLAGS.ACCESS_PKA_PROPERTY];

    const onView = useCallback(
        () => onSongWriterView(songWriter),
        [songWriter, onSongWriterView]
    );
    const history = useHistory();

    return (
        <tr key={songWriter.id}>
            <td>
                <div className="Songs-link" onClick={onView}>
                    {songWriter.legalName}
                </div>
            </td>
            {isPKAAccessible && (
                <td>{songWriter.professionallyKnownAs.join(', ')}</td>
            )}
            <td>{songWriter.pro || renderNotRegisteredCell()}</td>
            <td>{songWriter.ipi || renderNotRegisteredCell()}</td>
            <td>{renderAssociatedCompositionsCell(songWriter, history)}</td>
            <td>
                <SongWriterDropdown
                    category={category}
                    songWriter={songWriter}
                    onSongWriterView={onSongWriterView}
                    onSongWriterEdit={onSongWriterEdit}
                    onSongWriterDelete={onSongWriterDelete}
                />
            </td>
        </tr>
    );
};

export default SongWriterRow;
